import { getPage } from "./getPage";

export const getProduct = (products, containerType, data) => {
  const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  let productHTML = '';
  let numProducts;

  if (containerType === 'ha-product__content') {
    numProducts = 5;
    const randomProducts = [];
    while (randomProducts.length < numProducts) {
      const randomIndex = getRandomInt(0, products.length - 1);
      const randomProduct = products[randomIndex];
      if (!randomProducts.some(product => product.sku === randomProduct.sku)) {
        randomProducts.push(randomProduct);
      }
    }
    productHTML = randomProducts.map(product => `
      <div class="ha-product__box get_product" data-order-type="${product.sku}">
        <div class="home-product__box-img">
          <img src="/static/common/shared/products/${product.templates.imageLink}" alt="${product.templates.title}">
        </div>
        <span>${product.templates.title}</span>
        <div class="home-product__price">
          ${window.options?.currency ? window.options.currency : '$'}${product.price}
          <span> ${window.options?.currency ? window.options.currency : '$'}${product.templates.retail} </span>
        </div>
        <div class="home-product__hover">
          + View Product
        </div>
      </div>
    `).join('');
  } else if (containerType === 'home-product__content') {
    numProducts = products.length;
    let initialCount = 6;

    productHTML = products.map((product, index) => `
      <div class="home-product__box get_product ${index >= initialCount ? 'hidden-product' : ''}" 
           data-order-type="${product.sku}" data-index="${index}">
        <div class="home-product__box-img">
          <img src="/static/common/shared/products/${product.templates.imageLink}" alt="${product.templates.title}">
        </div>
        <span>${product.templates.title}</span>
        <div class="home-product__price">
          ${window.options?.currency ? window.options.currency : '$'}${product.price}
          <span> ${window.options?.currency ? window.options.currency : '$'}${product.templates.retail} </span>
        </div>
        <div class="home-product__hover">
          + View Product
        </div>
      </div>
    `).join('');

    productHTML += '<button id="loadMore" class="load-more-btn">Load More</button>';
  } else if (containerType === 'product-list') {
    numProducts = products.length;
    productHTML = products.map(product => `
       ${product.templates.title} <br>
       ${product.templates.title.toUpperCase()} BOTTLE - ${window.options?.currency ? window.options.currency : '$'}${product.price}<br> <br>
    `).join('');
  } else if (containerType === 'ap-content') {
    numProducts = products.length;
    productHTML = products.map(product => `
      <div class="ap-item get_product" data-order-type="${product.sku}">
        <div  class="ap-item__img">
          <img src="/static/common/shared/products/${product.templates.imageLink}" alt="${product.templates.title}">
        </div>
        <div class="ap-item__name">${product.templates.title}</div>
        <div class="ap-item__price">${window.options?.currency ? window.options.currency : '$'}${product.price}</div>
      </div>
    `).join('');
  } else if (containerType === 'banner-flex') {
    numProducts = 3;
    const randomProducts = [];
    while (randomProducts.length < numProducts) {
      const randomIndex = getRandomInt(0, products.length - 1);
      const randomProduct = products[randomIndex];
      if (!randomProducts.some(product => product.sku === randomProduct.sku)) {
        randomProducts.push(randomProduct);
      }
    }
    productHTML = randomProducts.map(product => `
      <div class="banner-box">
        <div class="banner-box__name">${product.templates.title}</div>
        <div class="banner-box__desc">${product.description}</div>
        <img src="/static/common/shared/products/${product.templates.imageLink}" alt="${product.templates.title}">
        <div class="banner-box__button get_product" data-order-type="${product.sku}">Discover Now</div>
      </div>
    `).join('');
  } else if (containerType === 'home-flex') {
    numProducts = products.length;
    productHTML = products.map(product => `
      <div class="home-flex-itm">
        <div class="home-flex-itm-left">
          <img src="/static/common/shared/products/${product.templates.imageLink}" alt="${product.templates.title}">
        </div>
        <div class="home-flex-itm-right">
          <h2 class='headerTitle'></h2>
          <a data-page="shop" class="get_page home-button button">Check the store</a>
        </div>
      </div>
    `).join('');
  }

  $(`.${containerType}`).html(productHTML);


  $('.get_product').click(function (e) {
    const $target = $(e.currentTarget);
    const sku = $target.data('order-type');
    localStorage.setItem('product', sku);

    window.location.href = `${getPage ('product')}&id=${sku}`
  });

  $(document).on('click', '.get_page', function () {
    const page = $(this).data('page');
    window.location.href = getPage(page);
  });

  if (data && data.templates && data.templates.headerTitle) {
    $('.headerTitle').html(data.templates.headerTitle);
  }
};
